import { miceService } from "@/service/miceService.js";
import {
    mapGetters
} from 'vuex'
import { Colors } from '@/utils/colors.js'
export default {
    data() {
        return {
            navBackgroundColor: {}
        }
    },
    computed: {
        ...mapGetters([
            'event'
        ])
    },
    mounted() {
        this.navBackgroundColor = Colors
    },
    methods: {
        //打开商宴通选择商家页面
        openMealSYT(params, callback) {
            miceService.openSYTShopList(params).then(res => {
                if (res && res.success) {
                    const tenant_code = this.event.tenant_code || 'eventcool';
                    const postData = {
                        loadUrl: res.content.url,
                        headerData: {
                            ...res.content.identifierHeader
                        },
                        browserMode: {
                            navBarVisibilty: true,
                            titleTextColor: "#ffffff",
                            navBackgroundColor: this.navBackgroundColor[tenant_code]
                        }
                    }
                    this.openNewWebSiteForResult(postData, callback)
                }
            })
        },
        //打开商宴通支付页面
        openTakeoutPaySYT(params, callback) {
            miceService.paySYT(params).then(res => {
                if(res && res.success) {
                    const tenant_code = this.event.tenant_code || 'eventcool';
                    const postData = {
                        loadUrl: res.content.url,
                        headerData: {
                            ...res.content.identifierHeader
                        },
                        browserMode: {
                            navBarVisibilty: true,
                            titleTextColor: "#ffffff",
                            navBackgroundColor: this.navBackgroundColor[tenant_code]
                        }
                    }
                    this.openNewWebSiteForResult(postData, callback)
                }
            })
        },
        //打开商宴通验证页面
        openCheckSYT(params, callback) {
            const tenant_code = this.event.tenant_code || 'eventcool';
            const postData = {
                loadUrl: params.content.url,
                headerData: {
                    ...params.content.identifierHeader,
                    ...this.header
                },
                browserMode: {
                    navBarVisibilty: true,
                    titleTextColor: "#ffffff",
                    navBackgroundColor: this.navBackgroundColor[tenant_code]
                }
            }
            this.openNewWebSiteForResult(postData, callback)
        },

        openNewWebSiteForResult(postData, callback) {
            const options = {
                method: "SMGeneral.openNewWebSiteForResult",
                postData: postData,
                callback: async () => {
                    callback && callback();
                }
            };
            this.iJsBridge.call(options);
        }
    }
}