<template>
    <span v-if="jiaoBiao.text && jiaoBiao.bgColor" class="eatingTag" :class="[jiaoBiao.bgColor]">
        {{ jiaoBiao.text }}
        <img class="eatingTail" src="../../assets/img/left.png" />
    </span>
</template>
<script>
export default {
    name: "JiaoBiao",
    props: ["item"],
    computed: {
        jiaoBiao: {
            get: function () {
                var item = this.item;
                let temp = (item.itsItemData || { payProfile: "" })
                    .payProfile || {
                    PayModes: [],
                };
                let payMode =
                    typeof temp.PayModes == "object"
                        ? temp.PayModes[0]
                        : temp.PayModes;
                var payModeText;
                if (item.itemType == 7) {
                    payMode = 12;
                    // payModeText = item.itemName;
                    switch (item.soucreCode) {
                        case 'ELE':
                            payModeText = '饿了么';
                            break;
                        case 'DianPing':
                            payModeText = '美团';
                            break;
                        case '"XiaoMiShu"':
                            payModeText = '小秘书';
                            break;
                        default:
                            payModeText = item.itemName;
                            break;
                    }
                }
                payMode = Number(payMode); //后端可能会返回为string类型
                var jiaoBiao = {};
                switch (payMode) {
                    case 1:
                        jiaoBiao = {
                            text: "闪惠",
                            bgColor: "shanhui",
                        };
                        break;
                    case 11:
                        jiaoBiao = {
                            text: "高德",
                            bgColor: "zhifubao",
                        };
                        break;
                    case 2:
                        jiaoBiao = {
                            text: "支付宝",
                            bgColor: "zhifubao",
                        };
                        break;
                    case 3:
                        jiaoBiao = {
                            text: "微信",
                            bgColor: "weixin",
                        };
                        break;
                    case 4:
                        jiaoBiao = {
                            text: "美餐",
                            bgColor: "meican",
                        };
                        break;
                    case 5:
                        jiaoBiao = {
                            text: "小秘书",
                            bgColor: "xms",
                        };
                        break;
                    case 6:
                        jiaoBiao = {
                            text: "口碑",
                            bgColor: "koubei",
                        };
                        break;
                    case 7:
                        jiaoBiao = {
                            text: "美团到餐",
                            bgColor: "meituandaocan",
                        };
                        break;
                    case 15:
                    case 8:
                        jiaoBiao = {
                            text: "饿了么",
                            bgColor: "eleme",
                        };
                        break;
                    case 9:
                        jiaoBiao = {
                            text: "商宴通",
                            bgColor: "shangyantong",
                        };
                        break;
                    case 10:
                        jiaoBiao = {
                            text: "商务卡",
                            bgColor: "shangwuka",
                        };
                        break;
                    case 12:
                        jiaoBiao = {
                            text: payModeText,
                            bgColor: item.sourceCode,
                        };
                        break;
                    default:
                        jiaoBiao = {
                            text: "线下餐",
                            bgColor: "xianxia",
                        };
                        break;
                }
                return jiaoBiao;
            },
        },
    },
};
</script>
<style lang="scss">
.eatingTag {
    // background: #ffbd00;
    color: #fff;
    // width: 0.5rem;
    height: 0.2rem;
    line-height: 0.2rem;
    display: inline-block;
    text-align: left;
    padding: 0 0.18rem 0 0.1rem;
    position: absolute;
    right: -0.3rem;
    top: -0.1rem;
    border-bottom-left-radius: 0.05rem;
    border-top-left-radius: 0.05rem;
    font-size: 0.1rem;

    .eatingTail {
        position: absolute;
        top: 0;
        right: -8px;
        width: 21px;
    }
}
.shanhui {
    background-color: #fc4e28;
}
.zhifubao {
    background-color: #00aaee;
}
.xms {
    background-color: #0ec771;
}
.XiaoMiShu {
    background-color: #0ec771;
}
.koubei {
    background-color: #e8470b;
}
.weixin {
    background-color: #ff821d;
}
.meican {
    background-color: #ff821d;
}
.xianxia {
    background-color: #9966cc;
}
.meituandaocan {
    background-color: #00ccc9;
}
.MeiCan {
    background-color: #11d350;
}
.ELE {
    background-color: #1e89e0;
}
.DianPing {
    background-color: #00ccc9;
}
.shangyantong {
    background-color: #0059aa;
}
.eleme {
    background-color: #1e89e0;
}
.shangwuka {
    background-color: #f7a608;
}
</style>
